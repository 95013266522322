<template>
  <HandleDataForms
    v-bind:apiSource="mongo"
    v-bind:dataSource="database"
    title="Formularios de Admisión especial extranjeros"
    collection="admision_especial_extranjeros"
    templateTable="TableAdmisionExtranjeros"
    templateTableModal="TableAdmisionExtranjerosModal"
    v-bind:dataExcel="dataExcel"
  />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const database = computed(() => store.getters.getDBDashboard)
    // eslint-disable-next-line no-unused-vars
    const mongo = computed(() => store.getters.getMongoFeriasURL)
    // const mongo = 'https://api.idastage.com/api/'

    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        fecha: 'Fecha',
        nombre: 'Nombre',
        apellido_paterno: 'Apellido paterno',
        apellido_materno: 'Apellido materno',
        nacionalidad_nombre: 'Nacionalidad',
        region_nombre: 'Región',
        comuna_nombre: 'Comuna',
        sede_nombre: 'Sede',
        ano_cursado: 'Año cursado',
        tipo_documento: 'Tipo de  documento',
        numero_documento: 'Nº de documento',
        email: 'E-mail',
        codigo_celular: 'Código celular',
        celular: 'Número celular',
        codigo_area: 'Código teléfono',
        telefono: 'Número teléfono',
        utm_source: 'UTM Source',
        utm_medium: 'UTM Medium',
        utm_campaign: 'UTM Campaign',
        device: 'Dispositivo',
        postulacion: [
          {
            carrera: {
              nombre: 'Carrera Nombre',
            },
            institucion: 'Carrera Institución',
            jornada: 'Carrera Jornada',
            zona_admision: {
              nombre: 'Carrera Zona de admisión',
            },
          },
        ],
        documento_0: 'Documento 1',
        documento_1: 'Documento 2',
        documento_2: 'Documento 3',
        documento_3: 'Documento 4',
      },
      query: {
        collection: 'admision_especial_extranjeros',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database,
    }
  },
}
</script>
